import React from 'react';
import './MiddleSection.css';
import { Link } from 'react-router-dom';
import myVideo from '../images/BengVid.mp4';



function MiddleSection() {
  return (
    <div className='middleSection-container'>
      <section className="box">  
        <video src={myVideo} autoPlay muted loop></video>
        <h1>Innover. Construire. Transformer.</h1> 
        <h3>L'ingénierie, notre passion, votre succès.</h3>
        <Link to="./contacts" className="boxBtn">LANCEZ VOUS</Link>   
      </section>
      
      <div className='acDescription'>
        L'équipe B-Eng apporte son expertise dans une gamme diversifiée de secteurs, de l'ouvrage 
            d'art à l'energie en passant par le calcul de structure et les séminaires de formation. Nous sommes à vos côtés à chaque étape
             pour vous garantir un service de qualité et des solutions à la pointe de la technologie.  
        
      </div>
      <div className='decouverte-b-eng'>
        <h2 className='decouverte-b-eng-title'>Découvrir B-Eng</h2>
        <div className='barre'></div>
        <div className='decouverte-b-eng-text-descriptif'>
            B-Eng est un concepteur, calculateur et designer de projets en génie civil avec un haut niveau d'expertise, de coût, de confort et de sécurité.
        </div>
      </div>
    </div>
    
  );
}

export default MiddleSection;
