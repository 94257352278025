import React, {useState, useRef} from 'react';
import imageContact from '../../images/imgContact.jpg';
import './Contacts.css';
import emailjs from '@emailjs/browser';
import { library } from '@fortawesome/fontawesome-svg-core';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, fab, faTwitter, faInstagram, faYoutubeSquare} from "@fortawesome/free-brands-svg-icons";
library.add(faFacebook, faLinkedin, fab, faTwitter, faInstagram, faYoutubeSquare);


function Contacts() {
    const [nomComplet, setNomComplet] = useState('');
    const [adresseEmail,setAdresseEmail] = useState('');
    const [message, setMessage] = useState('');
    const [nomCompletError, setNomCompletError] = useState('');
    const [adresseEmailError, setAdresseEmailError] = useState('');
    const [messageError, setMessageError] = useState('');

    const validateEmail = (adresseEmail) => {
      const emailRegular = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/ ;
      return emailRegular.test(adresseEmail);
    }
    const validateNomComplet = (nomComplet) => {
      const words = nomComplet.trim().split(' ');
      return nomComplet.trim() !== '' && words.length >= 2 && words[0] !== words[1];
    };
    const validateMessage = (message) => {
      return message.trim() !== '';
    }
     
    
    const form = useRef();

    const handleSubmit = async (e) => {
      e.preventDefault();

      const emailValid = validateEmail(adresseEmail);
      const nomCompletValid = validateNomComplet(nomComplet);
      const messageValid = validateMessage(message);

      setAdresseEmailError(emailValid ? '' : 'Adresse email invalide');
      setNomCompletError(nomCompletValid ? '' : 'Mettre le nom complet');
      setMessageError(messageValid ? '' : 'Message requis');

      if (emailValid && nomCompletValid && messageValid) {
        try {
          await emailjs.sendForm('service_7v4y7r9', 'template_pjdt891', form.current, {publicKey: 'HA3ggodusOHWNsJwk',});
          alert('Email envoyé');
          setNomComplet('');
          setAdresseEmail('');
          setMessage('');
        } catch (error) {
          console.error(error);
          alert('Erreur lors de la requête');
        }
      }

    }

    const myStyle = {
        backgroundImage: `linear-gradient(rgba(0, 0, 40, 0.5), rgba(0, 0, 0, 0.8)), url(${imageContact})`,
        marginTop: "0px",
        height: "100vh",
        fontSize: "50px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    };
    return (
    <>
      <div className='contact-container'>
    <div className='contact-wrapper'>
        <div style={myStyle} className='opening-contact'>
            <h1 className='phrase-contact'>L'avenir à portée de clic. <br />Contactez-nous pour transformer vos idées en réalité. </h1>
        </div> 
        <div className='contact-wrapper'>
            <div className='contactez-nous-wrapper'>
                <h2 className='contactez-nous'>Contactez-nous</h2>
                <div className='barre'></div>
            </div> 
            <div className='contact-content'>
                <div className='social-media'>
                    <Link
                        className='contact-social-icon fb'
                        to='https://www.facebook.com/profile.php?id=100063772966499'
                        target='_blank'
                        aria-label='Facebook'
                    >
                        <FontAwesomeIcon className='contact-icon fb' icon={['fab', 'facebook']} />
                    </Link>
                    <Link
                        className='contact-social-icon x'
                        to='/'
                        target='_blank'
                        aria-label='Twitter'
                    >
                        <FontAwesomeIcon className='contact-icon tw' icon={['fab', 'twitter']} />
                    </Link>
                    <Link
                        className='contact-social-icon in'
                        to='/'
                        target='_blank'
                        aria-label='LinkedIn'
                    >
                        <FontAwesomeIcon className='contact-icon in' icon={['fab', 'linkedin']} />
                    </Link>
                    <Link
                        className='contact-social-icon yt'
                        to='https://youtu.be/djeC9C7oTMU?si=ktHAqFrlXeSvc7k2'
                        target='_blank'
                        aria-label='Youtube'
                    >
                        <FontAwesomeIcon className='contact-icon yt' icon={['fab', 'youtube']} />
                    </Link>
                    <Link
                        className='contact-social-icon insta'
                        to='/'
                        target='_blank'
                        aria-label='Instagram'
                    >
                        <FontAwesomeIcon 
                            className='contact-icon insta' 
                            icon={['fab', 'instagram']} 
                        />                  
                    </Link>
                </div>
                <div className='formulaire-contact '>
                    <form ref={form} onSubmit={handleSubmit}>  
                        <h3 className='message-title'>Envoyez-nous un Message</h3>
                        <div className='message-content'>
                            <div className='box-input'>
                                <input 
                                    type='text'
                                    name='user_name' 
                                    required="required" 
                                    placeholder='Nom complet'
                                    value={nomComplet}
                                    onChange={(e) => setNomComplet(e.target.value)}
                                    onBlur={() => {
                                        if (!validateNomComplet(nomComplet)) {
                                            setNomCompletError('Mettre le nom complet');
                                        } else {
                                            setNomCompletError('');
                                        }
                                    }}
                                />                
                            </div>
                            {nomCompletError && (<p className='contact-form-error' >{nomCompletError} </p>)}
                            <div className='box-input'>
                                <input 
                                    type='text' 
                                    name='user_email' 
                                    required="required" 
                                    placeholder='Adresse Email' 
                                    value={adresseEmail}
                                    onChange={(e) => setAdresseEmail(e.target.value)}
                                    onBlur={() => {
                                        if (!validateEmail(adresseEmail)) {
                                            setAdresseEmailError('Adresse email invalide');
                                        } else {
                                            setAdresseEmailError('');
                                        }
                                    }}
                                />
                            </div>
                            {adresseEmailError && (<p className='contact-form-error'>{adresseEmailError}</p>)}
                            
                            <div className='box-input'>
                                <textarea 
                                    required="required" 
                                    name='message'
                                    placeholder='Ecrivez votre message'
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onBlur={() => {
                                        if (!validateMessage(message)) {
                                            setMessageError('Message requis');
                                        } else {
                                            setMessageError('');
                                        }
                                    }}
                                /> 
                            </div>
                            {messageError && (<p className='contact-form-error'>{messageError} </p>) }
                            <div className='box-input submit'>
                                <button className='envoyer' type='submit'>Envoyer</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>   
</>
) 
 
}

export default Contacts;
