import React, {useState} from 'react';
import './Navbar.css'
import {Link} from 'react-router-dom';
import logoImage from '../images/logoNav.png';

/*
import 'font-awesome/css/font-awesome.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 

library.add(faTimes, faBars);
*/

function Navbar() {
  const [click, setClick] = useState(false);
  

  const handleClick = () => {
    setClick(!click);
  }
  
  return (
    <>
    <nav className='navbar'>
            <Link to="/" className='logo-beng'>
              <img 
                src={logoImage}
                alt='logo-beng'
                className='logo-beng-nav'
              />
            </Link>  
            <ul className={click ?'nav-menu active':'nav-menu'}>
              <li className='nav-item'>
                <Link to='./apropos' className='nav-links' >
                  A propos
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='./expertise' className='nav-links' >
                  Expertise
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='./candidats' className='nav-links' >
                  Candidats
                </Link>
              </li>
              
              <li className='nav-item'>
                <Link to='./formation' className='nav-links' >
                  Formation
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='./contacts' className='nav-links' >
                  Contacts
                </Link>
              </li>
            </ul>
            <div className={click ?'nav__bars':'nav__toggler'} onClick={handleClick}>
              <div className='line1'></div>
              <div className='line2'></div>
              <div className='line3'></div>
            </div>      
    </nav>
    </>
  )
}

export default Navbar
