import React from 'react';
import './CarteFormation.css';

function CarteFormation({ niveau, description }) {
  return (
    <div className='formation-card'>
      <div className='formation-card-title'>{niveau}</div>
      <div className='formation-card-description'>{description}</div>
      
    </div>
  );
}

export default CarteFormation;
