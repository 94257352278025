import React from 'react';
import './ListeCartes.css';
import CarteElement from './CarteElement';
import myCardImage1 from '../images/im7.jpg';
import myCardImage2 from '../images/im9.jpg';
import myCardImage3 from '../images/imEnvironnement.jpg';

const cartes = [
    {
        key : 1,
        image : myCardImage1,
        titre : 'Génie civil',
        description: "Notre expertise en génie civil englobe la conception et la réalisation de structures robustes et durables, allant des bâtiments commerciaux aux infrastructures publiques."
    },
    {
        key : 2,
        image : myCardImage2,
        titre : 'Energie',
        description: "Développement de projets énergétiques durables : parcs éoliens, centrales solaires et hydroélectriques."
    },
    {
        key : 3,
        image : myCardImage3,
        titre : 'Environnement',
        description : "Approche intégrée de construction axée sur la durabilité, avec des études d'impact environnemental approfondies"
    },

]


function ListeCartes(){
    return(
        <div className='cartes__conteneur'>
            <ul className='liste__cartes'>
                {cartes.map(carte => (
                    <CarteElement key={carte.key} image={carte.image} titre={carte.titre} description={carte.description}/>
                ))}
               
            </ul>
        </div>  
    );
}

export default ListeCartes;