import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Accueil from './components/pages/Accueil';
import Apropos from './components/pages/Apropos';
import Expertise from './components/pages/Expertise';

import Candidats from './components/pages/Candidats';
import Contacts from './components/pages/Contacts';
import Formation from './components/pages/Formation';
import Navbar from './components/Navbar';
import PiedPage from './components/PiedPage';

function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Routes>
        <Route index element={<Accueil />} />
        <Route path='/accueil' element={<Accueil />}  />
        <Route path='/apropos' element={<Apropos />}  />
        <Route path='/expertise' element={<Expertise />}  />
        
        <Route path='/candidats' element={<Candidats />}  />
        <Route path='/contacts' element={<Contacts />}  />
        <Route path='/formation' element={<Formation />}  />
      </Routes>
      <PiedPage />   
    </Router>
    </>
  );
}


export default App;
