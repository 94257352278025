import React from 'react';
import './Candidats.css';
import  '../Navbar.css';
import BandeSection from '../BandeSection';
import candidatImage from '../../images/img12.jpg';


function Apropos() {
  return (
    <>  
      <div className='b-candidats'>
        <div className='introduction'>
            <h1 className='description-candidats'>Opportunités chez B-eng</h1>
            <p className='text-descriptif-candidats'>
                
                  Chez B-engineering, nous sommes constamment à la recherche de talents passionnés 
                  prêts à faire progresser l'industrie de la construction. Notre entreprise valorise 
                  vos compétences et votre engagement. Nous offrons un environnement dynamique 
                  et stimulant pour ceux qui aspirent à développer leur carrière dans un secteur en évolution constante.
                
            </p>
        </div>
        <BandeSection titreBande="Nous recrutons." cheminImage={candidatImage}/>
        <div className='corps-candidats'>
            <div className='see-more'> Pour en savoir plus</div>
            <div className='barre'></div>
        </div>
        <div className='sorry-no-offer'>
          <em>Actuellement, nous n'avons pas d'offres d'emploi disponibles. Cependant, veuillez rester à l'écoute pour de futures opportunités au sein de notre entreprise. </em>
        </div>
      </div>    
    </>
  )
}

export default Apropos
