import React from 'react';
import './Apropos.css';
import '../Navbar.css';
import imnum1 from '../../images/AncienProjetsBeng/imb10.jpeg';
import imnum2 from '../../images/AncienProjetsBeng/imb1.jpeg';
import imnum3 from '../../images/imNew1.jpeg';
import imnum4 from '../../images/AncienProjetsBeng/imb12.jpeg';
import imnum5 from '../../images/img19.jpg';
import imnum6 from '../../images/AncienProjetsBeng/imb6.jpeg';

function Card({ name, image, description }) {
  return (
    <figure className="snip1256">
      <img src={image} alt={name}/>
      <figcaption>
        <h3>{name}</h3>
        <div className='barreBlanche'></div>
        <p>{description}</p>
        
      </figcaption>
    </figure>
  );
}
function Apropos() {
  return (
    
    <div className='apropos-wrapper'>
      <div className='introduction'>
        <h1 className='description-apropos'>A propos de B-ENG</h1>
        <p className='text-descriptif-apropos'>
          Nous sommes spécialisés dans les services de construction et de génie civil orientés énergie et environnement, offrant des solutions personnalisées et de haute qualité.
        </p>
      </div>
      <div className='corps_apropos'>
        <div className='bengApropos'>Notre Histoire</div>
        <div className='barre'></div>
        <div className='histoire-texte'>Fondée par deux ingénieurs passionnés par le domaine du génie civil, notre entreprise a rapidement évolué pour devenir un acteur majeur dans le secteur de la construction, de l'environnement et de l'énergie. Depuis nos modestes débuts, nous avons réalisé des projets innovants et durables qui ont contribué à façonner le paysage urbain de nombreuses régions.</div>
        <div className='bengApropos'>En savoir plus </div>
        <div className='barre'></div>
        <div className="gallery">
          <Card name="Notre mission" image={imnum4} description="Notre mission : offrir des solutions d'ingénierie de qualité, respectueuses de l'environnement, tout en favorisant le développement durable et l'innovation, pour un avenir meilleur." />
          <Card name="Nos valeurs" image={imnum5} description="Chez B-Eng, nous sommes guidés par l'intégrité, l'excellence, le respect de l'environnement, la collaboration et l'innovation. Ces valeurs nous inspirent à surpasser les attentes de nos clients et à maintenir des normes élevées." />
          <Card name="Notre équipe" image={imnum6} description="Notre équipe diversifiée est passionnée et dévouée à la réussite de chaque projet. Notre culture favorise la collaboration, l'innovation et le développement professionnel, nous permettant de relever les défis avec confiance et expertise." />
        </div>
        <div className='bengApropos'>Nos réalisations </div>
        <div className='barre'></div>
        <div class="container-histoire">
              <div class="gallery-wrap">
                <div className="gallery-wrap">
                  <div className="item item-1"><img src={imnum1} alt="project 1" /></div>
                  <div className="item item-2"><img src={imnum2} alt="project 2" /></div>
                  <div className="item item-3"><img src={imnum3} alt="project 3" /></div>
                </div>
              </div>
        </div>
        
      </div>
        
     
    </div>
  );
}

export default Apropos;
