import React from 'react';
import './PiedPage.css';
import {Link} from 'react-router-dom';
//import 'font-awesome/css/font-awesome.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faFacebook, faLinkedin, fab, faTwitter, faInstagram, faYoutubeSquare} from "@fortawesome/free-brands-svg-icons"
import logoImage from '../images/logoFooter.png'
import {faPhone} from '@fortawesome/free-solid-svg-icons';

library.add(faFacebook, faLinkedin, fab, faTwitter, faInstagram, faYoutubeSquare,faPhone );

function PiedPage() {
  return (
    <div className='footer-container'>
      <div className='footer-logo-section'>
        <Link to="/" className='footer-logo'>
          <img 
              src={logoImage}
              alt='logo B-eng footer'
              className='logo-beng'
              style={{ maxWidth: '150px', height: 'auto'}}
          />
        </Link>
        
        
      </div>
      <div className='footer-links'>
        <div className='footer-links-wrapper'>
          <Link to='./apropos' className='footer-links-element' >
            A propos
          </Link>
          <Link to='./expertise' className='footer-links-element' >
            Expertise
          </Link>
          <Link to='./clients' className='footer-links-element' >
            Clients
          </Link>
        </div>
        <div className='footer-links-wrapper'>
          <Link to='./formation' className='footer-links-element' >
            Formation
          </Link>
          <Link to='./candidats' className='footer-links-element' >
            Candidats
          </Link>
          <Link to='./' className='footer-links-element' >
            Focus
          </Link>
        </div>
        <div className='footer-links-wrapper last-column'>
          <Link to='./' className='footer-links-element' >
            Catalogue de solutions
          </Link>
          <Link to='./' className='footer-links-element' >
            Actualité B-Eng
          </Link>  
        </div>
        
      </div>
      <div className='footer-social-media'>
        <button className='btn--footer'>CONTACTEZ-NOUS</button>
        <div className='footer-social-media-icons'>
            <Link
              className='social-icon-link facebook'
              to='https://www.facebook.com/profile.php?id=100063772966499'
              target='_blank'
              aria-label='Facebook'
            >
              <FontAwesomeIcon className='icon' icon={['fab', 'facebook']} />
            </Link>
            <Link
              className='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <FontAwesomeIcon className='icon-twitter' icon={['fab', 'twitter']} />
            </Link>
            <Link
              className='social-icon-link linkedin'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <FontAwesomeIcon className='icon' icon={['fab', 'linkedin']} />
            </Link>
            <Link
              className='social-icon-link youtube'
              to='https://youtu.be/djeC9C7oTMU?si=ktHAqFrlXeSvc7k2'
              target='_blank'
              aria-label='Youtube'
            >
              <FontAwesomeIcon className='icon-youtube' icon={['fab', 'youtube']} />
            </Link>
            <Link
              className='social-icon-link instagram'
              to='https://youtu.be/djeC9C7oTMU?si=ktHAqFrlXeSvc7k2'
              target='_blank'
              aria-label='Instagram'
            >
              <FontAwesomeIcon className='icon' icon={['fab', 'instagram']} />
            </Link>
            
        </div>
        <div className='telephone-b-eng'>
          <p className='telephone'> TELEPHONE : </p>
          <p className='num-tel'>00 221 77 842 52 51</p>
        </div>
      </div>
    </div>
  )
}

export default PiedPage;
