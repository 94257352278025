import React from 'react';
import imageFormation from '../../images/imFormation2.jpg';
import './Formation.css'
import CarteFormation from '../CarteFormation';

function Formation() {
  const myFormationStyle = {
    backgroundImage:`linear-gradient(rgba(0, 0, 5, 0.7), rgba(0, 0, 6, 0.8)), url(${imageFormation})`,
    marginTop: "0px",
    height: "100vh",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width:'100vw'
  };
  return (
    <>
      <div className='formation-container'>
        <div style={myFormationStyle} className='opening-formation'>
          <h1 className='phrase-ouverture'>Chez B-eng, notre passion est de vous transmettre notre expertise</h1>
        </div> 
        <div className='corps-container'>
          <div className='description-formations'>
            <h2 className='nosFormationsTitle'>Nos Formations</h2>
            <div className='barre'></div>
            <div class="intro-section">
              <p>Chez B-eng, nous croyons fermement à la puissance de l'apprentissage continu. Nous offrons une palette complète de formations sur mesure pour les professionnels de la construction, allant des cours en présentiel aux tutoriels vidéo en ligne.<br/> Notre objectif ? <br/> Développer et maintenir les compétences requises dans un secteur en constante évolution. De la maîtrise des techniques de construction à la gestion de projet, en passant par la sécurité sur les chantiers et l'utilisation des derniers logiciels de conception de calculs de structures, nos formations couvrent toutes les facettes essentielles pour exceller dans l'industrie de la construction.</p>
            </div>
          </div>
          <div className='formation-container'>
            <h3 className='niveaux-choix-formation'>Niveaux de formation</h3>
            <div className='barre-small'></div>
            <div className='cartes-container'>
              <CarteFormation
                niveau='Débutant'
                description='Explorez le génie civil et les normes de construction avec notre formation débutante. Maîtrisez les bases essentielles pour démarrer votre parcours dans ce domaine captivant.'
              />
              <CarteFormation
                niveau='Intermédiaire'
                description='Consolidez vos connaissances du génie civil et des normes de construction avec notre formation intermédiaire. Préparez-vous à des défis plus avancés dans ce domaine fascinant.'
              />
              <CarteFormation
                niveau='Avancé'
                description='Affinez votre expertise en génie civil et en normes de construction avec notre formation avancée. Devenez un professionnel hautement qualifié dans ce domaine dynamique.'
              />
            </div>
            <div className='contenu-formation-details'>
              <h3 className='niveaux-choix-formation'>Choix de formation</h3>
              <div className='barre-small'></div>
              <ul className='liste-externe'>
                <li>
                  <span>1. Normes de construction</span>
                  <ul className='liste-interne'>
                    <li>Normes Eurocodes</li>
                    <li>RCC-CM </li>
                    <li>RCC-CW </li>
                    
                  </ul>
                </li>
                <li>
                  <span>2. Logiciels de calculs</span>
                  <ul className='liste-interne'>
                    <li>GRAITEC</li>
                    <li>RSTAB</li>
                    <li>CBS</li>
                    <li>DDC</li>
                    <li>RSA</li>
                    <li>Autocad 2D</li>
                    <li>Archicad 3D</li>
                    <li>MS/Project</li>
                    <li>RETSCREEN</li>    
                  </ul>
                </li>
                <li>
                  <span>3. Remise à niveau</span>
                  <ul className='liste-interne'>
                    <li>Béton armé</li>
                    <li>RDM</li>
                    <li>Construction métallique</li>
                    <li>Plomberie</li>
                    <li>Dimensionnement en solaire et électricité</li>
                    <li>Remise à niveau pour les techniciens</li>
                    
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Formation;
