import './BandeSection.css';
import React from 'react';
 

function BandeSection(props) {
  return (
    <>
        <div className='bande-container'>
            <h2 className='bande-texte'>{props.titreBande}</h2>
            <img className='apropos-image1' alt='apropos de B-eng' src={props.cheminImage} />
        </div> 
    </>
  )
}

export default BandeSection;
