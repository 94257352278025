import React from 'react';
import './Expertise.css';
import imageExpertise from '../../images/imageExp.jpg';
import ingenierieImage from '../../images/image4.jpg';
import energieImage from '../../images/imageEnergie.jpg';
import environnementImage from '../../images/imageEnvironnement.jpg';

function Expertise() {
  const myStyle = {
    backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url(${imageExpertise})`,
    marginTop: "0px",
    height: "100vh",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    
  };
  return (
    <>
      <div className='expertise-container'>
        <div style={myStyle} className='opening-expertise'>
          <h1 className='phrase-ouverture'>Un Savoir-faire pour des solutions innovantes, performantes et durables </h1>
        </div> 
        <div className='corps_expertise'>
          <div className='color-band'></div>
          <div className='bande-texte-container'>
            <div className='text_container'>
              <p className='text_content'>
                Créée en 2023, <strong>B-engineering</strong> est une entreprise spécialisée dans l'ingénierie des infrastructures, œuvrant dans les secteurs du bâtiment, des travaux publics et des installations de production d'énergie.<br /> Notre expertise s'étend à la conception et à la réalisation de projets d'envergure, notamment la construction de barrages hydroélectriques, de parcs éoliens et de centrales nucléaires.<br /> Nous nous engageons à fournir des solutions innovantes et durables, répondant aux normes les plus élevées de qualité et de sécurité.
              </p>
            </div>
            <div className='detail-expertise'>
              <div className='section-ingenieur'>
                <div className='titre_wrapper_ingenieur'>
                  <h3 className='titre-item-ingenieur'>Ingénierie de conception et de calculs</h3>
                  <div className='barre'></div>
                </div>
                <div className='detail-item-ingenieur'>
                  <div className='description-ingenieries'>
                    <ol className='liste-expertise'>
                      <li className='liste-expertise-item'>Bâtiments.</li>
                      <li className='liste-expertise-item'>Travaux publics (routes, voies ferrées, ponts, tunnels..) </li>
                      <li className='liste-expertise-item'>Ouvrages de soutènements</li>
                    </ol>
                  </div>
                  <img className='detail-item-image' alt='ingenierie' src={ingenierieImage} /> 
                </div>
              </div>
              <div className='section-energie'>
                <div className='titre_wrapper_energie'>
                  <h3 className='titre-item-energie'>Energie</h3>
                  <div className='barre'></div>
                </div>
                <div className='detail-item-energie'> 
                  <div className='description-energie'>
                    <ol className='liste-expertise'>
                      <li className='liste-expertise-item'>Conception et dimensionnement de barrages hydro-électriques.</li>
                      <li className='liste-expertise-item'>Conception et dimensionnement de parc Éolien </li>
                      <li className='liste-expertise-item'>Conception et dimensionnement de parc solaire</li>
                      <li className='liste-expertise-item'>Conception et dimensionnement en electricité</li>
                    </ol>
                  </div>
                  <img className='detail-item-image' alt='energie' src={energieImage} />
                </div>
              </div>
              <div className='section-environnement'>
                <div className='titre_wrapper_environnement'>
                  <h3 className='titre-item-environnement'>Environnement</h3>
                  <div className='barre'></div>
                </div>
                <div className='detail-item-environnement'> 
                  <div className='description-environnement'>
                    <ol className='liste-expertise'>
                      <li className='liste-expertise-item'>Etude d’impacts environnementaux de projets de construction.</li>
                      <li className='liste-expertise-item'>Analyse de cycle de vie des matériaux de construction. </li>
                    </ol>
                  </div>
                  <img className='detail-item-image' alt='environnement' src={environnementImage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Expertise
