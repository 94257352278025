import  MiddleSection  from "../MiddleSection";
import React from 'react';
import './Accueil.css';
import ListeCartes from "../ListeCartes";


function Accueil() {
  return (
    <>
        
        <MiddleSection />
        <ListeCartes />
    </>
  )
}

export default Accueil;
